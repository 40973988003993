.timer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  background-color: var(--pure);
  padding: 0.6rem 1.5rem;
  top: -10%;
  left: 5%;
}
.timer h3 {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}
.timer .timer_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 2rem;
  border-radius: 50px;
  background-color: var(--dark-blue);
  padding: 0.6rem 1.5rem;
}
.timer .timer_container > div {
  text-align: center;
}
.timer .timer_container h3 {
  font-size: 0.875rem;
  color: var(--pure);
  margin: 0;
}
.timer .timer_container p {
  font-size: 0.75rem;
  text-transform: capitalize;
  color: var(--pure);
  margin: 0;
}
.timer .timer_container span {
  font-size: 1.5rem;
  margin: 0 0.8rem;
  color: var(--pure);
}

@media screen and (max-width: 934.99px) {
  .timer {
    top: -7% !important;
    left: 5%;
  }
  .product_card {
    flex-direction: column;
    min-width: 210px;
    max-width: 240px;
  }
  .product_card .card-img {
    width: 100% !important;
    height: 100% !important;
  }
}

@media screen and (max-width: 934.99px) {
  .timer {
    top: -7% !important;
    left: 5%;
  }
}

@media screen and (max-width: 767.99px) {
  .timer {
    top: -6% !important;
    left: 5% !important;
  }
  .timer h3 {
    font-size: 0.875rem;
  }
  .timer .timer_container {
    margin-left: 1rem !important;
    padding: 0.5rem 1rem;
  }
  .timer .timer_container > div {
    text-align: center;
  }
  .timer .timer_container h3 {
    font-size: 0.75rem !important;
  }
  .timer .timer_container p {
    font-size: 0.5rem !important;
  }
  .timer .timer_container span {
    font-size: 1.125rem;
    margin: 0 0.5rem;
  }
}
@media screen and (max-width: 425.99px) {
  .timer {
    padding: 0.5rem !important;
  }
  .timer h3 {
    font-size: 0.75rem;
  }
  .timer .timer_container {
    margin-left: 0.45rem !important;
    padding: 0.4rem 0.8rem !important;
  }
}
