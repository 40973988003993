.tag {
  font-size: 0.8rem;
  color: white;
  border-radius: 5px;
  padding: 0.1rem 0.3rem;
  font-weight: bold;
}
.overlay_tag {
  color: white;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 0.3rem 0.7rem;
  border-top-left-radius: 5px;
}

.bg-hot {
  background-color: rgb(255, 110, 0);
}

.section_spacing {
  margin: 3rem 0;
  padding: 3rem 0;
}
@media screen and (max-width: 1024.99px) and (min-width: 769px) {
  .section_spacing {
    margin: 2.5rem 0;
    padding: 2.5rem 0;
  }
}
@media screen and (max-width: 768.99px) and (min-width: 426px) {
  .section_spacing {
    margin: 2rem 0;
    padding: 2rem 0;
  }
}
@media screen and (max-width: 425.99px) {
  .section_spacing {
    margin: 1.5rem 0;
    padding: 1.5rem 0;
  }
}

/* Slick carousel css */
.slick_carousel_btn {
  display: none !important;
  background-color: var(--light);
  border: 2px solid #e7dbdb;
  border-radius: 100%;
  padding: 1rem 1.2rem;
  text-align: center;
  outline: none;
  color: var(--dark-gray);
  z-index: 1;
}
.slider_carousel_container:hover .slick_carousel_btn {
  display: block !important;
}
.slick_carousel_btn:hover {
  background-color: var(--light-red);
  color: var(--pure);
  transition: all 0.4s linear;
}
