.page_title .card-img {
  width: 100%;
  height: 180px !important;
}
.page_title .card-img-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 5%;
}
.page_title .card-img-overlay .card-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: capitalize;
}
.page_title .card-img-overlay .breadcrumb {
  margin: 0;
}
.page_title .card-img-overlay .breadcrumb-item a {
  text-decoration: none;
  color: var(--dark-blue);
}

/* Media Query */
@media screen and (max-width: 1024px) and (min-width: 425px) {
  .page_title .card-img-overlay .card-title {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 424.99px) {
  .page_title .card-img {
    height: 150px !important;
  }
}

@media screen and (max-width: 424.99px) {
  .page_title .card-img {
    height: 120px !important;
  }
  .page_title .card-img-overlay {
    padding: 0rem !important;
  }
  .page_title .card-img-overlay .card-title {
    font-size: 1.4rem;
    margin-bottom: 0.1rem;
  }
}
