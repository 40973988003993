/* Font Family */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

/* Bootstrap CSS */
@import "bootstrap/dist/css/bootstrap.min.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  max-width: 1920px;
  margin: auto;
}

.App .loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.App .loading img {
  width: 80px;
  height: 80px;
}

/* CSS */
@import "./styles/util.css";
@import "./styles/custom.css";
@import "./styles/variables.css";
