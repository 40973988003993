.container-fluid {
  width: 100%;
  padding: 0 3rem;
  margin: 0 auto;
}
@media screen and (min-width: 768px) and (max-width: 1026px) {
  .container-fluid {
    padding: 0 2rem;
  }
}
@media screen and (min-width: 424.99px) and (max-width: 767px) {
  .container-fluid {
    padding: 0 1.5rem;
  }
}
@media screen and (max-width: 425px) {
  .container-fluid {
    padding: 0 1rem;
  }
}

.card {
  border: 1px solid rgb(231, 219, 219) !important;
  border-radius: 5px !important;
  overflow: hidden;
}
.card .card-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
