.new_arrival_section .container-fluid .card-group {
  min-height: 300px;
}

.new_arrival_section .loader {
  position: relative;
  top: 0;
}

.new_arrival_section .card-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 240px));
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  align-items: center;
  justify-content: center;
}

.new_arrival_section .product_card .card-img-overlay {
  display: none;
}
