.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.loader img{
  width: 80px;
}

.progress {
  height: 10px !important;
  width: 100px;
  border: 3px solid var(--dark-red);
  border-radius: 15px;
  margin-top: 0.5rem;
}
.progress .color {
  background-color: rgb(250, 152, 152);
  width: 0px;
  height: 100%;
  border-radius: 15px;
  animation: progres 2s infinite linear;
}
@keyframes progres {
  0% {
    width: 0%;
  }
  25% {
    width: 25%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
} ;
