.about_us_page .about_us_details {
  border: none !important;
}
.about_us_page .about_us_details .card-title {
  font-size: 2rem;
  font-weight: 600;
}

.about_us_page .vision_mission {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}
.about_us_page .vision_mission .card {
  margin: 1rem;
  text-align: justify;
}
.about_us_page .vision_mission .card .card-title {
  font-weight: bold;
}

/* Media Query */
@media screen and (max-width: 768px) {
  .about_us_page .vision_mission {
    flex-direction: column;
  }
}
