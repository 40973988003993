/* -------------------- Error Page Styling Start -------------------- */
.page_not_found {
  min-height: calc(100vh - 5rem - 58.4px);
}

.page_not_found p {
  font-size: 1.1rem;
}

.page_not_found img {
  width: 50%;
}

.page_not_found .go_back_btn {
  background-color: var(--light-red);
  color: var(--pure);
  border-radius: 50px;
  border: 2px solid transparent;
  outline: none;
  padding: 0.2rem 1rem;
}

.page_not_found .go_back_btn:hover {
  background-color: transparent;
  border: 2px solid var(--light-red);
  color: var(--light-red);
}

/* -------------------- Error Page Styling End -------------------- */
