.slick-dots {
  bottom: -25% !important;
  width: 92%;
}
.slick-dots li {
  width: 100px;
  height: 100px;
}
.slick-dots li img {
  width: 100%;
  height: 100%;
}
.product_details_section .slick_carousel_btn {
  position: absolute;
  top: 45%;
}
.product_details_section .slider_carousel_container .slick_carousel_prev_btn {
  left: -2%;
}
.product_details_section .slider_carousel_container .slick_carousel_next_btn {
  right: -2%;
}

.product_details_section {
  margin: 2rem 0;
  min-height: 90vh;
}
.product_details_section .container-fluid {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.product_details_section .slider_carousel_container {
  flex: 0.3;
  margin: auto;
  padding: 1rem;
  width: 30%;
}
.product_details_section .slider_carousel_container .product_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product_details_section .product_details {
  flex: 0.5;
  padding: 1rem;
  text-transform: capitalize;
}
.product_details .badge {
  font-size: 1rem;
  font-weight: 400;
  padding: 0.4rem 1.2rem;
  border-radius: 5px;
}
.product_details .category_tag {
  background-color: #cff4fc !important;
  color: #055160 !important;
  border: 1px solid #b6effb !important;
}
.product_details .sub_category_tag {
  background-color: #d6fccf !important;
  color: #226005 !important;
  border: 1px solid #c9fbb6 !important;
}
.product_details .rating {
  justify-content: flex-start;
}

.product_details .price {
  margin: 1rem 0;
}
.product_details .price div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
}
.product_details .price div p {
  font-size: 3rem;
  font-weight: bold;
  color: var(--dark-red);
  margin: 0;
}
.product_details .price div s {
  font-size: 1.8rem;
  margin: 0 0.8rem;
  margin-top: 0.7rem;
  color: var(--dark-gray);
}
.product_details .description {
  text-align: justify;
}
.product_details .size span {
  font-weight: bold;
}

.product_details .colour span {
  font-weight: bold;
}

@media screen and (max-width: 1250px) and (min-width: 960px) {
  .product_details_section .slider_carousel_container {
    flex: 0.4;
    width: 40%;
  }
}
@media screen and (max-width: 960.99px) and (min-width: 768px) {
  .slick-dots {
    width: 90%;
  }
  .slick-dots li {
    width: 80px;
    height: 80px;
  }
  .product_details_section .slick_carousel_btn {
    padding: 0.8rem 1rem;
  }
  .product_details_section .slider_carousel_container {
    flex: 0.45;
    width: 30%;
  }
  .product_details h1 {
    font-size: 1.8rem;
  }
  .product_details .price div p {
    font-size: 2.2rem;
  }
  .product_details .price div s {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 768.99px) and (min-width: 650px) {
  .slick-dots {
    width: 90%;
  }
  .slick-dots li {
    width: 80px;
    height: 80px;
  }
  .product_details_section .slick_carousel_btn {
    padding: 0.6rem 0.8rem;
  }
  .product_details_section .slider_carousel_container {
    flex: 0.5;
    width: 30%;
  }
  .product_details h1 {
    font-size: 1.6rem;
  }
  .product_details .price div p {
    font-size: 2rem;
  }
  .product_details .price div s {
    font-size: 1.2rem;
    margin: 0 0.4rem;
    margin-top: 0.5rem;
  }

  .product_details .description,
  .product_details .size,
  .product_details .colour {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 650px) and (min-width: 580px) {
  .slick-dots {
    bottom: -20% !important;
  }
  .product_details_section .slider_carousel_container {
    flex: 1;
    width: 80%;
  }
  .product_details_section .product_details {
    flex: 1;
    margin-top: 10rem;
  }
  .product_details_section .container-fluid {
    flex-direction: column;
  }
}
@media screen and (max-width: 580px) and (min-width: 425px) {
  .product_details_section .slider_carousel_container {
    flex: 1;
    width: 100%;
  }
  .product_details_section .product_details {
    flex: 1;
    margin-top: 10rem;
  }
  .product_details_section .container-fluid {
    flex-direction: column;
  }
}
@media screen and (max-width: 425px) {
  .slick-dots {
    width: 100%;
  }
  .slick-dots li {
    width: 75px;
    height: 75px;
  }
  .product_details_section .slick_carousel_btn {
    padding: 0.5rem 0.7rem;
  }
  .product_details_section .slider_carousel_container .slick_carousel_prev_btn {
    left: -5%;
  }
  .product_details_section .slider_carousel_container .slick_carousel_next_btn {
    right: -5%;
  }
  .product_details_section .container-fluid {
    flex-direction: column;
  }
  .product_details_section .slider_carousel_container {
    flex: 1;
    width: 100%;
    padding: 0;
  }
  .product_details_section .product_details {
    flex: 1;
    margin-top: 10rem;
    padding: 0;
  }
  .product_details h1 {
    font-size: 1.6rem;
  }
  .product_details .price div p {
    font-size: 2rem;
  }
  .product_details .price div s {
    font-size: 1.2rem;
    margin: 0 0.4rem;
    margin-top: 0.5rem;
  }
  .product_details .description,
  .product_details .size,
  .product_details .colour {
    font-size: 0.9rem;
  }
}
