/*  Footer Styling Start */
footer {
  background-color: var(--light);
}

footer .footer_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 0;
}

footer .footer_top .logo img {
  width: 60px;
  height: 60px;
}

footer .footer_top .social_media svg {
  padding: 0 1rem;
  color: var(--dark-blue);
  font-size: 1.5rem;
  cursor: pointer;
}

footer .footer_center {
  display: flex;
  justify-content: space-around;
  padding: 2rem 0;
  border-top: 1px solid var(--dark-gray);
  border-bottom: 1px solid var(--dark-gray);
}
footer .footer_center > div h4 {
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem 0;
  color: var(--dark-blue);
}
footer .footer_center p {
  color: var(--dark-gray);
  font-size: 0.875rem;
}
footer .footer_center ul {
  padding: 0;
}
footer .footer_center ul li {
  list-style: none;
  margin: 0.5rem 0;
}
footer .footer_center ul li a {
  text-decoration: none;
  font-size: 0.875rem;
  color: var(--dark-gray);
}
footer .footer_center ul li:hover a {
  color: var(--dark-red);
  transform: all 0.4s linear;
}
footer .footer_center .bio {
  flex: 0.25;
}

footer .footer_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
  color: var(--dark-blue);
}

footer .footer_bottom .copy_write a {
  text-decoration: none;
  color: var(--light-red);
  margin: 0 0.5rem;
}

footer .footer_bottom .payment svg {
  padding: 0 1rem;
  color: var(--dark-blue);
  font-size: 1.5rem;
}

/*  Footer Styling End */

/* Media Query Start */
@media screen and (min-width: 880px) and (max-width: 1026px) {
  footer .footer_bottom .payment svg {
    padding: 0 0.5rem;
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 881px) {
  footer .footer_bottom .copy_write {
    font-size: 0.875rem;
  }
  footer .footer_bottom .payment svg {
    padding: 0 0.4rem;
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 424.99px) and (max-width: 767px) {
  footer .footer_top .social_media svg {
    padding: 0 0.5rem;
    font-size: 1.125rem;
  }

  footer .footer_center {
    flex-direction: column;
  }
  footer .footer_center > div {
    margin: 1rem;
  }

  footer .footer_bottom {
    flex-direction: column-reverse;
  }
  footer .footer_bottom .copy_write {
    font-size: 0.875rem;
    margin-top: 1rem;
    text-align: center;
  }
  footer .footer_bottom .payment svg {
    padding: 0 0.4rem;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 425px) {
  footer .footer_top .logo img {
    width: 50px;
    height: 50px;
  }
  footer .footer_top .social_media svg {
    padding: 0 0.5rem;
    font-size: 1.125rem;
  }
  footer .footer_center {
    flex-direction: column;
  }
  footer .footer_center > div {
    margin: 1rem;
  }
  footer .footer_bottom {
    flex-direction: column-reverse;
  }
  footer .footer_bottom .copy_write {
    font-size: 0.875rem;
    margin-top: 1rem;
    text-align: center;
  }
  footer .footer_bottom .payment svg {
    padding: 0 0.4rem;
    font-size: 1.3rem;
  }
}

/* Media Query End */
