.popular_category_section .slick_carousel_btn {
  position: absolute;
  top: 40%;
}
.popular_category_section .slider_carousel_container:hover .slick_carousel_btn {
  display: block !important;
}
.popular_category_section .slick_carousel_btn:hover {
  background-color: var(--light-red);
  color: var(--pure);
  transition: all 0.4s linear;
}
.popular_category_section .slick_carousel_prev_btn {
  left: -1%;
}
.popular_category_section .slick_carousel_next_btn {
  right: -1%;
}

.popular_category_section .category_card {
  padding: 0 1rem !important;
}

.popular_category_section .category_card:hover .product_img img {
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
}

.popular_category_section .category_card .product_img {
  overflow: hidden;
  border: 1px solid #e7dbdb;
}

.popular_category_section .category_card .product_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
}

.popular_category_section .category_card .details h2 {
  font-size: 1.125rem;
  margin-top: 1rem;
  color: var(--dark-blue);
  font-weight: bold;
  text-transform: capitalize;
}

.popular_category_section .category_card .details p {
  margin: 0.5rem 0;
  color: var(--dark-gray);
}

.popular_category_section .category_card > a {
  text-decoration: none;
  color: var(--dark-blue);
  transition: all 0.4s linear;
  outline: none;
}

.popular_category_section .category_card > a .faPlayCircle {
  color: var(--light-red);
  margin-left: 0.5rem;
}

.popular_category_section .category_card > a:hover {
  color: var(--dark-red);
  margin-left: 0.5rem;
}

@media screen and (max-width: 1024px) and (min-width: 901px) {
  .popular_category_section .slider_carousel_container {
    max-width: 920px !important;
    padding: 0 4rem !important;
  }
}
@media screen and (max-width: 900.99px) and (min-width: 741px) {
  .popular_category_section .slider_carousel_container {
    max-width: 760px !important;
    padding: 0 2rem !important;
  }
}
@media screen and (max-width: 740.99px) and (min-width: 580px) {
  .popular_category_section .slider_carousel_container {
    max-width: 580px !important;
    padding: 0 3rem !important;
  }
}
@media screen and (max-width: 500.99px) {
  .popular_category_section .slick_carousel_btn {
    padding: 0.8rem 1rem;
  }
  .popular_category_section .slick_carousel_prev_btn {
    left: -3%;
  }
  .popular_category_section .slick_carousel_next_btn {
    right: -3%;
  }
  .popular_category_section .slider_carousel_container {
    max-width: 360px !important;
    padding: 0 2rem !important;
  }
}
