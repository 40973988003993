.hero_carousel_section .carousel {
  margin: 0.5rem;
}
.hero_carousel_section .carousel-item {
  position: relative !important;
}
.hero_carousel_section .carousel_overlay {
  background: linear-gradient(rgba(16, 29, 44, 0.7), rgba(16, 29, 44, 0.7))
    center/cover no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  border-radius: 10px;
}
.hero_carousel_section .carousel-item img {
  height: calc(100vh - 100px - 34px - 0.5rem);
  max-height: 768px;
  border-radius: 10px;
  object-fit: cover;
}
.hero_carousel_section .carousel-caption {
  top: 50%;
  left: 5% !important;
  transform: translate(-5%, -50%);
  text-align: left !important;
  max-width: 40%;
}
.hero_carousel_section .carousel-caption h1 {
  font-size: 3rem;
  font-weight: normal;
  color: var(--pure);
}
.hero_carousel_section .carousel-caption h1:nth-child(2) {
  font-weight: bolder;
}
.hero_carousel_section .carousel-caption p {
  margin: 1rem 0 2.5rem 0;
  font-size: 1.2rem;
  color: var(--light);
}
.hero_carousel_section .carousel-caption a {
  background-color: var(--dark-red);
  border: 2px solid var(--dark-red);
  border-radius: 50px;
  outline: none;
  padding: 0.6rem 1.5rem;
  color: var(--pure);
  font-weight: bold;
  text-decoration: none;
}
.hero_carousel_section .carousel-control-prev,
.hero_carousel_section .carousel-control-next {
  display: none !important;
}

/* -------------------- Media Query Start -------------------- */
@media screen and (min-width: 881px) and (max-width: 1260px) {
  .hero_carousel_section .carousel-caption {
    max-width: 50%;
    margin: 0 0.8rem;
  }
  .hero_carousel_section .carousel-caption h1 {
    font-size: 2.5rem;
  }
  .hero_carousel_section .carousel-caption p {
    margin: 1rem 0 2rem 0;
    font-size: 1.1rem;
  }
  .hero_carousel_section .carousel-caption a {
    font-size: 0.875rem;
    padding: 0.5rem 1.2rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 880.99px) {
  .hero_carousel_section .carousel-caption {
    max-width: 50%;
    margin: 0 1rem;
  }
  .hero_carousel_section .carousel-caption h1 {
    font-size: 2rem;
  }
  .hero_carousel_section .carousel-caption p {
    margin: 0.9rem 0 1.8rem 0;
    font-size: 1rem;
  }
  .hero_carousel_section .carousel-caption a {
    font-size: 0.8rem;
  }
}
@media screen and (orientation: portrait) {
  .hero_carousel_section .carousel-item img {
    height: 570px;
  }
}
@media screen and (min-width: 565px) and (max-width: 767.99px) {
  .hero_carousel_section .carousel-caption {
    max-width: 60%;
    margin: 0 1rem;
  }
  .hero_carousel_section .carousel-caption h1 {
    font-size: 1.9rem;
  }
  .hero_carousel_section .carousel-caption p {
    margin: 0.8rem 0 1.6rem 0;
    font-size: 0.9rem;
  }
  .hero_carousel_section .carousel-caption a {
    font-size: 0.7rem;
  }
}
@media screen and (min-width: 425px) and (max-width: 564.99px) {
  .hero_carousel_section .carousel-caption {
    max-width: 80%;
    margin: 0 1rem;
  }
  .hero_carousel_section .carousel-caption h1 {
    font-size: 1.7rem;
  }
  .hero_carousel_section .carousel-caption p {
    margin: 0.6rem 0 1.2rem 0;
    font-size: 0.875rem;
  }
  .hero_carousel_section .carousel-caption a {
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 424.99px) {
  .hero_carousel_section .carousel-caption {
    min-width: 90%;
    margin: 0 0.8rem;
  }
  .hero_carousel_section .carousel-caption h1 {
    font-size: 1.5rem;
  }
  .hero_carousel_section .carousel-caption p {
    margin: 0.5rem 0 1rem 0;
    font-size: 0.8rem;
  }
  .hero_carousel_section .carousel-caption a {
    font-size: 0.5rem;
    padding: 0.4rem 1rem;
  }
}
