.blogs_details_page .blog_details {
  text-align: justify;
  margin: 3rem 0;
}
.blogs_details_page .blog_details .image {
  float: right;
  min-width: 250px;
  max-width: 30vw;
  margin: 2rem;
}
.blogs_details_page .blog_details .image img {
  width: 100%;
}

/* Media Query */

@media screen and (max-width: 650px) {
  .blogs_details_page .blog_details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .blogs_details_page .blog_details .image {
    float: none;
    min-width: 70vw;
  }
}
