/*  Blog Page Styling Start */
.blog_section .blog_card {
  width: 100%;
  height: 100%;
}

.blog_section .blog_card .blog_img {
  overflow: hidden;
  width: 100%;
}
.blog_section .blog_card .blog_img img {
  width: 100%;
  height: 100%;
  transition: all 0.4s ease-in-out;
}
.blog_section .blog_card:hover .blog_img img {
  transform: scale(1.1);
}

.blog_section .blog_card .blog_details .card-title {
  color: var(--dark-blue);
  margin: 1rem 0;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.4;
}
.blog_section .blog_card .blog_details .card-text {
  color: var(--dark-gray);
  font-size: 0.8;
  margin: 1rem 0;
  text-align: justify;
}

.blog_section .blog_card #blog_details.hide {
  display: none;
  transition: all 0.4s ease-in-out;
}
.blog_section .blog_card .blog_details.show {
  display: block;
  transition: all 0.4s ease-in-out;
}

.blog_section .blog_card .read_more {
  text-decoration: none;
  color: var(--dark-blue);
  transition: all 0.4s ease-in-out;
}
.blog_section .blog_card .read_more:hover {
  margin-left: 0.5rem;
  color: var(--light-red);
}
.blog_section .blog_card .read_more svg {
  color: var(--light-red);
  margin-left: 0.4rem;
}
/*  Blog Page Styling End */
/* 
@media screen and (min-width: 768px) and (max-width: 881px) {
  .blog_section .blog_card .blog_details .card-title {
    font-size: 1.125rem;
  }
  .blog_section .blog_card .blog_details .card-text {
    font-size: 0.8rem;
  }

  .blog_section .blog_card .blog_details .read_more span,
  .blog_section .blog_card .blog_details .read_more svg {
    font-size: 0.875rem;
  }
}
@media screen and (min-width: 424.99px) and (max-width: 767px) {
  .blog_section .blog_card {
    margin: 1rem 0;
  }
} */
