.hot_deals_section .deals_slider_container {
  border: 2px solid var(--dark-blue);
  border-radius: 10px;
  width: 95%;
  margin: auto;
  padding: 5rem 0 3rem 0;
  position: relative;
}

.hot_deals_section .deals_slider_container .slick-slider {
  padding: 0 1.5rem;
}
.hot_deals_section .deals_slider_container .slick-slider .slick-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deals_slider_container .product_card {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0 1rem;
}
.deals_slider_container .product_card .card-img-overlay {
  display: none;
}
.deals_slider_container .product_card .card-img {
  width: 210px;
  object-fit: fill;
}


@media screen and (max-width: 1360.99px) and (min-width: 1024.99px) {
  .deals_slider_container .product_card {
    flex-direction: column;
    min-width: 210px;
    max-width: 240px;
  }
  .deals_slider_container .product_card .image_container {
    width: 100%;
    height: 100%;
  }
  .deals_slider_container .product_card .image_container img{
    width: 100%;
  }
}
@media screen and (max-width: 934.99px) {
  .deals_slider_container .product_card {
    flex-direction: column;
    min-width: 210px;
    max-width: 240px;
  }
  .deals_slider_container .product_card .image_container {
    width: 100%;
    height: 100%;
  }
  .deals_slider_container .product_card .image_container img{
    width: 100%;
  }
}

.hot_deals_section .deals_btn {
  display: none !important;
  background-color: var(--light);
  border: 2px solid #e7dbdb;
  border-radius: 100%;
  padding: 1rem 1.2rem;
  text-align: center;
  outline: none;
  color: var(--dark-gray);
  z-index: 1;
  position: absolute;
  top: 40%;
}
.hot_deals_section .deals_slider_container:hover .deals_btn {
  display: block !important;
}
.hot_deals_section .deals_btn:hover {
  background-color: var(--light-red);
  color: var(--pure);
  transition: all 0.4s linear;
}
.hot_deals_section .deals_prev_btn {
  left: 1%;
}
.hot_deals_section .deals_next_btn {
  right: 1%;
}

@media screen and (max-width: 881px) {
  .hot_deals_section .deals_prev_btn {
    left: 2% !important;
  }
  .hot_deals_section .deals_next_btn {
    right: 2% !important;
  }
}
