.rating {
  margin: 0.3rem 0;
  display: flex;
  align-items: center;
}
.rating .stars {
  color: orange;
  font-size: 1.4rem !important;
}
.rating .value {
  color: var(--dark-gray);
  margin-left: 8px;
}
