.product_card_container {
  max-width: 300px;
}

.product_card .image_container {
  overflow: hidden;
}

.product_card .image_container .card-img {
  transition: all 0.4s ease-in-out;
  height: 240px !important;
  width: 240px;
  object-fit: fill;
}
.product_card:hover .card-img {
  transition: all 0.4s ease-in-out;
  transform: scale(1.1);
}

.product_card .card-body {
  text-align: center;
}

.product_card .card-body .card-subtitle {
  font-size: 0.8rem;
  color: var(--dark-gray);
  text-transform: uppercase;
  margin: 0.5rem 0 !important;
}
.product_card .card-body .card-title {
  color: var(--dark);
  margin: 0 !important;
  font-weight: bold !important;
  font-size: 1.1rem;
  text-transform: capitalize !important;
  text-overflow: ellipsis;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.product_card .card-body .rating {
  justify-content: center;
}

.product_card .card-body .price {
  display: flex;
  align-items: center;
  justify-content: center;
}
.product_card .card-body .price p {
  color: var(--dark-red);
  font-size: 1.125rem;
  margin: 0 0.6rem;
}
.product_card .card-body .price s {
  font-size: 0.875rem;
  color: var(--dark-gray);
}
