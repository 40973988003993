/*  Services Section Styling Start */

.our_values_section .slick-slide > div {
  margin: 1rem;
}

.our_values_section .values_detail {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 250px;
  min-height: 320px;
  text-align: center;
  border: 2px solid black;
  padding: 2rem;
  border: 1px solid rgb(231, 219, 219);
  border-radius: 5px;
  color: var(--dark-blue);
  outline: none;
  transform: all 0.4s linear;
}

.our_values_section .values_detail h3 {
  margin: 1rem 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.our_values_section .values_detail svg {
  color: var(--dark-gray);
  font-size: 2rem;
}

.our_values_section .values_detail:hover {
  border-color: var(--light-red);
}

.our_values_section .values_detail:hover i {
  color: var(--light-red);
}

/*  Services Section Styling End */
