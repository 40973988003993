/* -------------------- Topbar Styling Start -------------------- */
.topbar {
  background-color: var(--dark-blue);
  color: var(--pure);
  padding: 0.4rem 0;
}
.topbar .topbar_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
}
.topbar .icons a {
  color: var(--pure);
  margin-right: 0.6rem;
}
.topbar .contact_info {
  display: flex;
  align-items: center;
}
.topbar .contact_info > div a {
  color: var(--pure);
  text-decoration: none;
  margin-left: 0.35rem;
}
.topbar .contact_info .divider {
  padding: 0 1rem;
}
/* -------------------- Topbar Styling End -------------------- */

/* -------------------- Navbar Styling Start -------------------- */
.navbar_logo {
  width: 60px;
  height: 60px;
}
.nav_link,
#basic-nav-dropdown {
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 1.1rem;
  color: var(--dark-blue);
  margin-left: 1.5rem;
  padding: 0.2rem 0;
}
.nav_link:hover,
#basic-nav-dropdown:hover {
  color: var(--dark);
  font-weight: bold;
}

.nav_link::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 2px;
  border-radius: 4px;
  background-color: var(--dark-red);
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}
.nav_link:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

/* -------------------- Navbar Styling End -------------------- */

/* -------------------- Mobile-View Navbar Styling Start -------------------- */
.mobile_divider {
  border: 0.5px solid var(--dark);
  background-color: var(--dark);
  margin: 3rem 1.5rem;
  display: none;
}
.mobile_topbar {
  margin: 1.5rem;
  display: none;
}

.mobile_topbar .icons a {
  color: var(--dark);
  margin-left: 1rem;
}
.mobile_topbar .contact_info div {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
.mobile_topbar .contact_info p {
  color: var(--dark);
  margin-bottom: 0;
}
.mobile_topbar .contact_info svg {
  margin-right: 0.5rem;
}
/* -------------------- Mobile-View Navbar Styling End -------------------- */

@media screen and (max-width: 580px) {
  .topbar {
    display: none;
  }
  .mobile_divider {
    display: block;
  }
  .mobile_topbar {
    display: block;
  }
}
