.products_page_section .products_container {
  min-height: 80vh;
  margin: 5rem 0;
}

.products_page_section .loader {
  position: absolute;
  top: 70%;
  min-height: 80vh;
}

.products_page_section .no_data_container {
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}
.products_page_section .no_data_container .card-img {
  width: 280px;
  height: 280px;
}
.products_page_section .no_data_container .card-title {
  margin-top: 1rem;
}

.products_page_section .sort_by_box {
  margin: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.products_page_section .sort_by_box p {
  margin: 0 1rem;
}
.products_page_section .sort_by_box .dropdown button {
  border-radius: 0;
}

.products_page_section .card-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 240px));
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

/* Pagination */
.products_page_section .pagination_box .pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}
.products_page_section .pagination_box .pagination li {
  border-radius: 50%;
  padding: 0.5rem 0.9rem;
  margin: 0.2rem;
  cursor: pointer;
}
.products_page_section .pagination_box .pagination li:hover {
  background-color: var(--light);
  border-radius: 50%;
}
.products_page_section .pagination_box .pagination li.active {
  background-color: gainsboro;
}
.products_page_section .pagination_box .pagination li.disabled a {
  color: #bbbbbb;
}
.products_page_section .pagination_box .pagination li.active a {
  color: var(--dark);
  font-weight: bold;
}
.products_page_section .pagination_box .pagination li a {
  text-decoration: none;
  color: var(--dark-gray);
}

/* Media Query */
@media screen and (max-width: 1024px) and (min-width: 425px) {
  .products_page_section .pagination_box .pagination li {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 424.99px) {
  .products_page_section .pagination_box .pagination li {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 424.99px) {
  .products_page_section .no_data_container .card-img {
    width: 200px;
    height: 200px;
  }
  .products_page_section .no_data_container .card-title {
    font-size: 1rem;
  }
  .products_page_section .pagination_box .pagination li {
    font-size: 0.7rem;
    padding: 0.4rem 0.8rem;
  }
}
