:root {
  --dark-red: #cf2829;
  --light-red: #ef1e1e;

  --dark-blue: #212c31;
  --dark-gray: #666666;

  --light: #f6f5f5;
  --pure: white;

  --dark: black;
}
