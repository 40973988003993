.section_titel {
  text-align: center;
}
.section_titel .t1 {
  font-size: 1.5rem;
  color: var(--dark-blue);
  margin-bottom: 0.5rem;
  font-weight: bold;
}
.section_titel .t3 {
  font-size: 0.875rem;
  color: var(--dark-gray);
}
.section_titel hr {
  width: 50px;
  margin: 1rem auto 3rem auto;
  border: 2px solid var(--dark-red);
  background-color: var(--dark-red);
}
